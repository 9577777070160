var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.statLoading,
          expression: "statLoading"
        }
      ],
      staticClass: "bsItem-statistics"
    },
    [
      _c("div", { staticClass: "bsItem-statistics__header" }, [
        _c(
          "div",
          { staticClass: "bsItem-statistics__factor" },
          [
            _c("label", [_vm._v("所属角色")]),
            _c(
              "el-select",
              {
                attrs: {
                  size: "small",
                  filterable: "",
                  clearable: "",
                  placeholder: "所有"
                },
                model: {
                  value: _vm.factors.functionName,
                  callback: function($$v) {
                    _vm.$set(_vm.factors, "functionName", $$v)
                  },
                  expression: "factors.functionName"
                }
              },
              _vm._l(_vm.newFunctionDicList, function(item) {
                return _c("el-option", {
                  key: item.roleTagId,
                  attrs: { label: item.roleTagName, value: item.roleTagName }
                })
              }),
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "bsItem-statistics__factor" },
          [
            _c("label", [_vm._v("批注时间")]),
            _c("el-date-picker", {
              attrs: {
                size: "small",
                type: "daterange",
                align: "center",
                "unlink-panels": "",
                "range-separator": "至",
                "start-placeholder": "开始日期",
                "end-placeholder": "结束日期"
              },
              model: {
                value: _vm.factors.date,
                callback: function($$v) {
                  _vm.$set(_vm.factors, "date", $$v)
                },
                expression: "factors.date"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "bsItem-statistics__factor export" },
          [
            _c(
              "el-button",
              {
                attrs: { size: "small", disabled: _vm.exporting, plain: "" },
                on: { click: _vm.exportExcel }
              },
              [_vm._v("导出")]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "bsItem-statistics__factor reset" },
          [
            _c(
              "el-button",
              {
                attrs: { size: "small", plain: "" },
                on: { click: _vm.clearFilter }
              },
              [_vm._v("重置")]
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "bsItem-statistics__body" },
        [
          _c(
            "el-table",
            {
              ref: "filterTable",
              staticClass: "is-grey",
              attrs: {
                "highlight-current-row": "",
                data: _vm.tableData,
                height: "100%",
                size: "medium"
              },
              on: { "filter-change": _vm.handleFilterChange }
            },
            [
              _c("el-table-column", {
                attrs: {
                  "show-overflow-tooltip": "",
                  type: "index",
                  "min-width": "30px",
                  label: "序号",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  "min-width": "200px",
                  "header-align": "left",
                  align: "left",
                  label: "条文内容",
                  prop: "content"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("div", {
                          staticStyle: { width: "100%", overflow: "hidden" },
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.xss(row.content.replaceAll("\n", "<br>"))
                            )
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  "show-overflow-tooltip": "",
                  "min-width": "50px",
                  label: "所属角色",
                  align: "center",
                  prop: "functionName"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  "show-overflow-tooltip": "",
                  "min-width": "50px",
                  label: "问题相关项目数",
                  align: "center",
                  prop: "problemCount"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  "min-width": "50px",
                  label: "模块",
                  align: "center",
                  prop: "moduleName"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  "min-width": "50px",
                  label: "部位",
                  align: "center",
                  prop: "positionName"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  "show-overflow-tooltip": "",
                  label: "操作",
                  "min-width": "50px",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.seeProject(row)
                              }
                            }
                          },
                          [_vm._v("查看相关项目")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          key: _vm.currentCkpId,
          attrs: {
            visible: _vm.projectVisible,
            title: "问题归属项目统计",
            width: "40%"
          },
          on: {
            "update:visible": function($event) {
              _vm.projectVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.projectLoading,
                  expression: "projectLoading"
                }
              ],
              staticClass: "project__body"
            },
            [
              _c("div", { staticClass: "ckp__content" }, [
                _c("span", [_vm._v("当前条文内容：")]),
                _c("span", [_vm._v(_vm._s(_vm.currentContent))])
              ]),
              _c("div", { ref: "chartPanel", staticClass: "charts_panel" })
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }