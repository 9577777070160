import "core-js/modules/es.array.slice.js";

/**
 * Echarts选项生成辅助函数
 * @param {Object} xAxisData 轴坐标数据
 * @param {Object} seriesData series数据
 * @returns Echarts选项
 */
export default function generatorCustomizeOption(xAxisData, seriesData) {
  return {
    grid: {
      top: "30",
      left: "0",
      right: "0",
      bottom: "40",
      containLabel: true
    },
    title: {
      text: '项目统计',
      top: "0",
      textStyle: {
        fontSize: 14
      }
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow"
      },
      backgroundColor: '#f4f5fa'
    },
    legend: {
      bottom: "10",
      type: 'scroll'
    },
    xAxis: {
      type: 'category',
      data: xAxisData,
      axisLine: {
        lineStyle: {
          color: '#646464',
          width: 1
        }
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        rotate: 30,
        formatter: function formatter(value, index) {
          var val = value || '';

          if (val.length > 11) {
            return '...' + value.slice(val.length - 9, val.length);
          }

          return value;
        }
      }
    },
    yAxis: [{
      type: 'value',
      axisLine: {
        lineStyle: {
          color: '#D4D9EC'
        }
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#EAEFFF'],
          width: 1,
          type: 'solid'
        }
      },
      axisLabel: {
        showMaxLabel: true,
        interval: 0,
        rotate: 0,
        color: '#666666'
      }
    }],
    series: [{
      type: 'bar',
      name: '项目问题数量',
      barWidth: 14,
      data: seriesData
    }]
  };
}